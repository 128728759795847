import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#576ddb",
        secondary: "#955ae7",
        accent: "#a06e3b",
        error: "#be4678",
        warning: "#aa573c",
        info: "#398bc6",
        success: "#2a9292",
      },
      light: {
        primary: "#576ddb",
        secondary: "#955ae7",
        accent: "#a06e3b",
        error: "#be4678",
        warning: "#aa573c",
        info: "#398bc6",
        success: "#2a9292",
      },
    },
  },
});
