<template>
  <v-list>
    <v-list-item
      v-for="(favorite, i) in favorites"
      :key="i"
      :href="favorite.url"
    >
      <v-list-item-avatar tile>
        <v-img :src="favorite.icon"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="favorite.text"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "Favorites",

  data: () => ({
    favorites: [
      {
        icon: "/img/flipboard.png",
        text: "Flipboard",
        url: "https://flipboard.com",
      },
      {
        icon: "/img/weather.png",
        text: "The Weather Channel",
        url: "https://weather.com",
      },
      {
        icon: "/img/google-maps.png",
        text: "Google Maps",
        url: "https://maps.google.com",
      },
      {
        icon: "/img/google.png",
        text: "Google Search",
        url: "https://google.com",
      },
      {
        icon: "/img/pandora.png",
        text: "Pandora",
        url: "https://pandora.com",
      },
      {
        icon: "/img/arvest.png",
        text: "Arvest Bank",
        url: "https://www.arvest.com/",
      },
      {
        icon: "/img/google-translate.png",
        text: "Google Translate",
        url: "https://translate.google.com",
      },
      {
        icon: "/img/enterprise.png",
        text: "Enterprise",
        url: "https://www.enterprise.com",
      },
      {
        icon: "/img/egencia.png",
        text: "Egencia",
        url: "https://www.egencia.com/auth/v1/login?isRedirected=true",
      },
      {
        icon: "/img/certify.png",
        text: "Certify",
        url: "https://www.certify.com",
      },
      {
        icon: "/img/cnn.png",
        text: "CNN",
        url: "https://www.cnn.com",
      },
    ],
  }),
  created() {},
};
</script>
