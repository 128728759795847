<template>
  <v-app
    style="background: url(/bg.jpg) no-repeat center center fixed;background-size: cover;"
  >
    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <span class="headline ml-3 mr-5 d-none d-lg-block"
        >{{ this.greeting }} Jaime</span
      >
      <v-spacer></v-spacer>
      <v-text-field
        class="mr-5"
        solo-inverted
        flat
        hide-details
        v-model="query"
        label="Wolfram Alpha Query..."
        prepend-inner-icon="mdi-comment-question"
        v-on:keyup.enter="wolfram"
      ></v-text-field>
      <v-btn text @click="wolfram" class="ma-2 d-none d-lg-block" large
        >Ask</v-btn
      >
      <v-spacer></v-spacer>
      <span v-if="weather.weather">{{ weather.weather[0].main }}</span>
      <img
        v-if="weather.weather"
        :src="
          `https://openweathermap.org/img/wn/${weather.weather[0].icon}.png`
        "
      />
      <span class="mr-3" v-if="weather.main"
        >{{ ~~(((weather.main.temp - 273.15) * 9) / 5 + 32) }}°</span
      >
      <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        bottom
        color="secondary"
      ></v-progress-linear>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app clipped>
      <Favorites />
    </v-navigation-drawer>
    <v-dialog v-model="answerDialog" max-width="290">
      <v-card dark color="secondary">
        <v-list-item-content>
          <v-card-text class="headline">{{ answer }}</v-card-text>
        </v-list-item-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              answerDialog = false;
              answer = null;
            "
          >
            Close
          </v-btn>
          <v-btn text :href="`https://www.wolframalpha.com/input/?i=${query}`">
            More...
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="stream.length" class="mt-12 pt-12">
      {{ stream }}
      <v-row justify="end">
        <iframe
          frameborder="0"
          height="1200"
          width="1600"
          :src="stream.stream"
          allowfullscreen
          scrolling="no"
          allowtransparency
        ></iframe>
      </v-row>
    </v-container>
    <v-container v-else fluid class="mt-12 pt-12">
      <v-row justify="end">
        <News id="jaime0" />
        <News id="jaime1" />
        <News id="jaime2" />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import News from "./components/News";
import Favorites from "./components/Favorites";
const WA_URL = "https://srv.lastra.us/wolfram/";
const STREAM_URL = "https://srv.lastra.us/stream";
const OW_KEY = "45447c86e329f103f5631bf6f17f543e";
const CITY = "4128894";
const OW_URL = `//api.openweathermap.org/data/2.5/weather?id=${CITY}&appid=${OW_KEY}`;

export default {
  name: "App",

  components: {
    News,
    Favorites,
  },

  data: () => ({
    stream: null,
    drawer: true,
    loading: true,
    query: null,
    answer: null,
    answerDialog: false,
    hour: new Date().getHours(),
    weather: {},
  }),
  methods: {
    wolfram: function() {
      this.loading = true;
      this.$http.get(`${WA_URL}${this.query}`).then((response) => {
        this.answer = response.data;
        this.answerDialog = true;
        this.loading = false;
      });
    },
  },
  created() {
    this.$http.get(STREAM_URL).then((response) => {
      this.stream = response.data;
    });
    this.$http.get(OW_URL).then((response) => {
      this.weather = response.data;
      this.loading = false;
    });
    setInterval(() => {
      this.loading = true;
      this.hour = new Date().getHours();
      this.$http.get(OW_URL).then((response) => {
        this.weather = response.data;
        this.loading = false;
      });
    }, 3600000);
  },
  computed: {
    greeting() {
      let greeting = "";
      if (this.hour < 12) {
        greeting = "Buenos días";
      } else if (this.hour < 17) {
        greeting = "Buenas tardes";
      } else {
        greeting = "Buenas noches";
      }
      return greeting;
    },
  },
};
</script>
