<template>
  <v-col lg="3">
    <v-hover v-slot:default="{ hover }">
      <div class="font-weight-light display-2 text-capitalize mt-5 mb-5">
        {{ preset.name || "..." }}

        <v-dialog scrollable v-model="editDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-expand-transition>
              <v-btn
                class="transition-fast-in-fast-out "
                v-if="hover"
                icon
                color="grey"
                v-bind="attrs"
                v-on="on"
                v-on:click="loadSources"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </v-expand-transition>
          </template>
          <v-card color="secondary" dark>
            <v-card-title>
              <span class="headline">Edit News Preset</span>
            </v-card-title>
            <v-card-text style="height: 600px;">
              <v-radio-group row v-model="preset.type">
                <v-radio label="Source" value="source"></v-radio>
                <v-radio label="Category & Country" value="category"></v-radio>
              </v-radio-group>
              <v-container>
                <v-row>
                  <v-col md="8">
                    <v-text-field v-model="preset.name" label="Name">
                    </v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="preset.pageSize"
                      label="Amount (1-100)"
                      type="number"
                      min="1"
                      max="100"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col md="6" v-if="preset.type == 'category'">
                    <v-select
                      v-model="preset.country"
                      :items="countries"
                      label="Country"
                      item-text="country"
                      item-value="abbr"
                    ></v-select>
                  </v-col>
                  <v-col md="6" v-if="preset.type == 'category'">
                    <v-select
                      v-model="preset.category"
                      :items="categories"
                      label="Category"
                    ></v-select>
                  </v-col>
                  <v-col md="12">
                    <v-text-field v-model="preset.q" label="Keywords">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-text-field
                v-if="preset.type == 'source'"
                outlined
                v-model="sourceSearch"
                label="Search sources..."
                hide-details="auto"
              ></v-text-field>

              <v-list
                v-if="preset.type == 'source'"
                color="secondary"
                three-line
              >
                <v-list-item-group
                  v-model="preset.sources"
                  multiple
                  active-class=""
                >
                  <v-list-item
                    v-for="source in filteredSources"
                    :key="source.id"
                    :value="source.id"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ source.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          source.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="editDialog = false">Close</v-btn>
              <v-btn text @click="savePreset(preset)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-hover>

    <v-card
      v-for="(article, index) in articles"
      :key="index"
      hover
      color="primary darken-1"
      dark
      class="mb-5 "
      :href="article.url"
    >
      <v-list-item>
        <v-list-item-content>
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on }">
              <v-list-item-title v-on="on" class="title">
                {{ article.title.split(" - ")[0] }}
              </v-list-item-title>
            </template>
            <span>{{ article.title }}</span>
          </v-tooltip>
          <v-list-item-subtitle>{{ article.source.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-img v-if="article.urlToImage" :src="article.urlToImage"></v-img>

      <v-card-text class="primary" v-if="article.description">
        {{ article.description }}
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
const NA_SOURCES = "https://srv.lastra.us/sources/";
const NA_NEWS = "https://srv.lastra.us/news/";
const categories = [
  "business",
  "entertainment",
  "general",
  "health",
  "science",
  "sports",
  "technology",
];
const countries = [
  { abbr: "ae", country: "United Arab Emirates" },
  { abbr: "ar", country: "Argentina" },
  { abbr: "at", country: "Austria" },
  { abbr: "au", country: "Australia" },
  { abbr: "be", country: "Belgium" },
  { abbr: "bg", country: "Bulgaria" },
  { abbr: "br", country: "Brazil" },
  { abbr: "ca", country: "Canada" },
  { abbr: "ch", country: "Switzerland" },
  { abbr: "cn", country: "China" },
  { abbr: "co", country: "Colombia" },
  { abbr: "cu", country: "Cuba" },
  { abbr: "cz", country: "Czechia" },
  { abbr: "de", country: "Germany" },
  { abbr: "eg", country: "Egypt" },
  { abbr: "fr", country: "France" },
  { abbr: "gb", country: "United Kingdom" },
  { abbr: "gr", country: "Greece" },
  { abbr: "hk", country: "Hong Kong" },
  { abbr: "hu", country: "Hungary" },
  { abbr: "id", country: "Indonesia" },
  { abbr: "ie", country: "Ireland" },
  { abbr: "il", country: "Israel" },
  { abbr: "in", country: "India" },
  { abbr: "it", country: "Italy" },
  { abbr: "jp", country: "Japan" },
  { abbr: "kr", country: "Korea" },
  { abbr: "lt", country: "Lithuania" },
  { abbr: "lv", country: "Latvia" },
  { abbr: "ma", country: "Morocco" },
  { abbr: "mx", country: "Mexico" },
  { abbr: "my", country: "Malaysia" },
  { abbr: "ng", country: "Nigeria" },
  { abbr: "nl", country: "Netherlands" },
  { abbr: "no", country: "Norway" },
  { abbr: "nz", country: "New Zealand" },
  { abbr: "ph", country: "Philippines" },
  { abbr: "pl", country: "Poland" },
  { abbr: "pt", country: "Portugal" },
  { abbr: "ro", country: "Romania" },
  { abbr: "rs", country: "Serbia" },
  { abbr: "ru", country: "Russia" },
  { abbr: "sa", country: "Saudi Arabia" },
  { abbr: "se", country: "Sweden" },
  { abbr: "sg", country: "Singapore" },
  { abbr: "si", country: "Slovenia" },
  { abbr: "sk", country: "Slovakia" },
  { abbr: "th", country: "Thailand" },
  { abbr: "tr", country: "Turkey" },
  { abbr: "tw", country: "Taiwan" },
  { abbr: "ua", country: "Ukraine" },
  { abbr: "us", country: "United States" },
  { abbr: "ve", country: "Venezuela" },
  { abbr: "za", country: "South Africa" },
];
export default {
  name: "News",
  props: ["id"],
  data: () => ({
    articles: [],
    countries: countries,
    categories: categories,
    sources: [],
    sourceSearch: "",
    editDialog: false,
    preset: {},
  }),
  methods: {
    getNews: function() {
      this.$http.get(`${NA_NEWS}${this.id}`).then((response) => {
        this.articles = response.data.articles;
        this.preset = response.data.preset;
        console.log(response.data);
      });
    },
    savePreset: function(preset) {
      this.$http.post(`${NA_NEWS}${this.id}`, preset).then((response) => {
        console.log(response);
        this.articles = response.data.articles;
        this.preset = response.data.preset;
      });
      console.log(preset);
      this.editDialog = false;
    },
    loadSources: function() {
      this.$http.get(NA_SOURCES).then((response) => {
        this.sources = response.data.sources;
      });
    },
  },
  computed: {
    filteredSources() {
      if (this.sourceSearch) {
        return this.sources.filter((source) => {
          console.log(typeof source.name);
          if (
            source.name &&
            source.name.toLowerCase().includes(this.sourceSearch.toLowerCase())
          ) {
            return source.name;
          }
        });
      } else {
        return this.sources;
      }
    },
  },
  created() {
    this.getNews();
  },
};
</script>
